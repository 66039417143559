.custom-button {
  border-radius: 2px !important;
  // border: 1px solid transparent;
  // font-size: 16px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  span:not([class]) {
    font-size: 14px;
  }
  &::after {
    display: none;
  }
  &:hover {
    // border: 1px solid transparent;
  }
  &:disabled {
    background: #f5f5f5 !important;
    border: 1px solid #f0f0f0 !important;
  }
  &.ant-btn-link {
    // color: #910d11 !important;
    padding: unset;
  }
  &.main {
    // background: #910d11 !important;
    // color: white !important;
    // &:hover,
    // &:focus {
    //   border: 1px solid transparent;
    //   background: #a7353c !important;
    // }
    // &:active,
    // &:visited {
    //   background: #811209 !important;
    //   border-color: #811209;
    // }
    // &:disabled {
    //   background: #f5f5f5 !important;
    //   border: 1px solid #f0f0f0 !important;
    //   color: rgba(0, 0, 0, 0.25) !important;
    // }
  }

  &.secondary {
    // background: white !important;
    // border: 1px solid #d9d9d9 !important;
    // color: rgba(0, 0, 0, 0.85) !important;
    // &:hover,
    // &:focus {
    //   background: #fafafa !important;
    //   border: 1px solid #f0f0f0 !important;
    // }
    // &:active,
    // &:visited {
    //   background: #f0f0f0 !important;
    //   border: 1px solid #d9d9d9 !important;
    // }
    // &:disabled {
    //   background: #f5f5f5 !important;
    //   color: rgba(0, 0, 0, 0.25) !important;
    // }
  }
  &.cyan {
    background: #03a89c !important;
    color: white !important;
  }
  &.cyan-link {
    color: #03a89c !important;
  }
  &.success {
    background: #237804 !important;
    color: white !important;
  }
  &.warning {
    background: #faad14 !important;
    color: black !important;
  }
  &.danger {
    background: #f5222d !important;
    color: white !important;
  }
  &.view {
    border-color: transparent;
    background-color: #f5f5f5 !important;
    color: rgba(0, 0, 0, 0.85) !important;
    &:active,
    &:focus {
      // border-color: #910d11;
    }
    &.cyan {
      &:active,
      &:focus {
        // border-color: #03a89c;
      }
    }
  }
  &.edit {
    background-color: #f4e0e3 !important;
    border-color: transparent;
    color: #910d11 !important;
    // &:focus {
    //   border-color: #f4e0e3 !important;
    // }
    &.cyan {
      background-color: #e0f7f2 !important;
      color: #03a89c !important;
    }
  }
  &.remove {
    border-color: transparent;
    background-color: #fff1f0 !important;
    color: #f5222d !important;
    &:focus {
      border-color: #fff1f0 !important;
    }
  }
}

.ant-btn > .anticon {
  line-height: 0;
}
