// @import '~antd/dist/antd.css';
@import '~antd/dist/antd.variable.min.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;

@layer base {
  ul,
  ol {
    display: block;
    list-style: disc outside none;
    margin: 1em 0;
    padding: 0 0 0 40px;
  }
  ol {
    list-style-type: decimal;
  }
  li {
    display: list-item;
  }
  ul ul,
  ol ul {
    list-style-type: circle;
    margin-left: 15px;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
    margin-left: 15px;
  }
}

@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
  position: relative;
  font-family: 'Roboto', sans-serif;
  color: rgba(0, 0, 0, 0.85);

  #root {
    height: 100%;
    width: 100%;
  }

  p,
  hr,
  // ul,
  section {
    margin: 0;
    padding: 0;
  }
}

.ant-modal-mask {
  background: #262626 !important;
  opacity: 0.8 !important;
}

p {
  margin-bottom: 0;
}

.text-primary {
  @apply text-910D11;
}

.layout-login {
  background-image: url('../resources/images/bg-login.png');
}
