.avatar-edit {
  position: relative;
  .icon-edit {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover {
    .icon-edit {
      display: block;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: #2f54eb;
      opacity: 0.5;
    }
  }
}
.avatar-edit-qltt {
  &::after {
    background-color: #910d11 !important;
  }
}
.avatar-edit-bql {
  &::after {
    background-color: #03a89c !important;
  }
}

.ck-editor__editable[role='textbox'] {
  /* editing area */
  min-height: 150px;
}

:root {
  .ck.ck-editor__editable_inline {
    border: 1px solid var(--ck-color-base-border);
  }
}

.custom-tree {
  .ant-tree-switcher-leaf-line {
    &::before {
      display: none !important;
    }
    &::after {
      display: none !important;
    }
  }
}

.after-colon {
  position: relative;
  &::after {
    content: ':';
    position: absolute;
    right: -12px;
    top: 0;
    width: auto;
  }
}

.before-colon {
  position: relative;
  &::after {
    content: ':';
    position: absolute;
    left: -5px;
    top: 0;
    width: auto;
  }
}

.word-break {
  word-break: break-word;
}

.disable-element {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none;
}
